import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { Branding, WebAppState } from '@models';
import { signalStore, withHooks, withMethods, withState } from '@ngrx/signals';

type LoginState = 'loggedOut' | 'initialization' | 'loggedIn';

interface AppState {
  branding: Branding;
  isOnline: boolean;
  loginState: LoginState;
  appLanguage: 'en';
  selectedScene: { sceneGuid: string; originalMultiSceneGuid?: string };
  webAppState: WebAppState;
  viewPort: { width: number; height: number };
}

const initialState: AppState = {
  branding: 'manta',
  isOnline: true,
  loginState: 'loggedOut',
  appLanguage: 'en',
  selectedScene: { sceneGuid: '6c21c6e3-489c-5da6-ac38-f2a3c5715a89' },
  webAppState: 'inactive',
  viewPort: { width: 0, height: 0 },
};

export const AppStore = signalStore(
  { providedIn: 'root' },
  withDevtools('app'),
  withState(initialState),
  withMethods((store) => ({
    setScene(sceneGuid: string, originalMultiSceneGuid?: string): void {
      updateState(store, '[App] Set scene', {
        selectedScene: { sceneGuid, originalMultiSceneGuid },
      });
    },
    setLoginState(loginState: LoginState): void {
      updateState(store, '[App] Login state: ' + loginState, {
        loginState,
      });
    },
    setBranding(branding: Branding): void {
      updateState(store, '[App] Set scene', {
        branding,
      });
    },
    setOnlineState(isOnline: boolean): void {
      updateState(store, '[App] Online state', {
        isOnline,
      });
    },
    setWebAppState(webAppState: WebAppState): void {
      updateState(store, '[App] WebAppState:' + webAppState, {
        webAppState,
      });
    },
  })),
  withHooks({
    onInit(store) {
      window.addEventListener('online', () =>
        updateState(store, '[App] Set online', { isOnline: true }),
      );
      window.addEventListener('offline', () =>
        updateState(store, '[App] Set offline', { isOnline: false }),
      );
      updateState(store, '[App] Set initial viewport', {
        viewPort: { width: window.innerWidth, height: window.innerHeight },
      });

      window.addEventListener('resize', () => {
        updateState(store, '[App] Set viewport', {
          viewPort: { width: window.innerWidth, height: window.innerHeight },
        });
      });
    },
  }),
);
