import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { RefreshTokenData, TokenData } from './models/api/token.interfaces';
import { environment } from '../../../environments/environment';

const HTTP_SCOPE =
  'openid resource_roles virtamed_connect_rest_api offline_access';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private http = inject(HttpClient);

  token: TokenData = {} as TokenData;

  revokeUserToken(userName: string, password: string, token: string) {
    return this.http.post<TokenData>(
      environment.api + '/api/users/identity/token/revoke',
      {
        userName,
        password,
        scope: HTTP_SCOPE,
        client: 'FrontEnd',
      },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        }),
      },
    );
  }

  getUserToken(username: string, password: string): Observable<TokenData> {
    return this.http
      .post<TokenData>('/api/users/identity/token', {
        UserName: username,
        Password: password,
        Scope: HTTP_SCOPE,
        Client: 'FrontEnd',
      })
      .pipe(
        tap((token) => {
          this.token = token;
        }),
        catchError((error) => {
          console.error('Error fetching user token:', error);
          return throwError(() => error);
        }),
      );
  }

  acquireAccessToken(token = ''): Observable<RefreshTokenData> {
    const formData = new URLSearchParams();
    formData.append('refresh_token', token);
    formData.append('client_id', 'VirtaMed.Connect.RestAPI');
    formData.append(
      'client_secret',
      'secret_471FF943-31E2-403D-9D8C-6B3D7BB4553B',
    );
    formData.append('grant_type', 'refresh_token');

    return this.http.post<RefreshTokenData>(
      '/api/users/identity/token',
      formData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      },
    );
  }
}
