import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { SceneReport, UploadState } from '@models';
import {
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { DataStore } from '@stores';
import { withSyncToStorage } from './withSyncToStorage.feature';

interface ReportsState {
  reports: SceneReport[];
  currentReportId: string | null;
}

const initialState: ReportsState = {
  reports: [],
  currentReportId: null,
};

export const ReportsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('reports'),
  withState(initialState),
  withSyncToStorage(['reports']),
  withComputed(({ reports }, dataStore = inject(DataStore)) => ({
    states: computed(
      (): {
        pending: number;
        uploaded: number;
        completed: number;
        failed: number;
      } => ({
        pending: reports().filter(
          (report) => report.state === UploadState.pending,
        ).length,
        uploaded: reports().filter(
          (report) => report.state === UploadState.uploaded,
        ).length,
        completed: reports().filter(
          (report) => report.state === UploadState.completed,
        ).length,
        failed: reports().filter(
          (report) => report.state === UploadState.failed,
        ).length,
      }),
    ),
    localizedReports: computed((): SceneReport[] =>
      reports().map((report) => {
        const sceneSection = dataStore
          .sceneSections()
          .find((sceneSection) =>
            sceneSection.scenes.find(
              (scene) => scene.identifier === report.data.sceneIdentifier,
            ),
          );
        return {
          ...report,
          data: {
            ...report.data,
            name:
              sceneSection?.scenes.filter(
                (scene) => scene.identifier === report.data.sceneIdentifier,
              )[0]?.name ?? '',
            sectionName: sceneSection?.name ?? '',
          },
        };
      }),
    ),
  })),
  withComputed(({ currentReportId, localizedReports }) => ({
    currentReport: computed(
      (): SceneReport =>
        localizedReports().find(
          (report) => report.identifier === currentReportId(),
        ) ?? ({} as SceneReport),
    ),
  })),
  withMethods((store) => {
    return {
      addReport(report: SceneReport): void {
        updateState(store, '[Reports] Add report', (state: ReportsState) => {
          const id = crypto.randomUUID();
          return {
            ...state,
            reports: [report, ...state.reports],
            currentReportId: report.identifier,
          };
        });
      },
      changeState(id: string, uploadState: UploadState): void {
        updateState(
          store,
          '[Reports] Change report state',
          (state: ReportsState) => ({
            ...state,
            reports: state.reports.map((report) =>
              report.identifier === id
                ? { ...report, state: uploadState }
                : report,
            ),
          }),
        );
      },
      setReports(reports: SceneReport[]): void {
        updateState(store, '[Reports] Set reports', (state: ReportsState) => ({
          ...state,
          reports,
        }));
      },
    };
  }),
);
