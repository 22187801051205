import { ReportUpload } from '@api';

export interface SceneReport {
  identifier: string;
  userId: number;
  state: UploadState;
  data: ReportUpload & {
    name: string;
    sectionName: string;
  };
}

export enum UploadState {
  pending = 'pending',
  uploaded = 'uploaded',
  notUploaded = 'notUploaded',
  completed = 'completed',
  failed = 'failed',
}
