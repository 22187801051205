import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UserStore } from '@stores';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  RefreshTokenData,
  TokenData,
} from '../authentication/models/api/token.interfaces';
import { TokenService } from '../authentication/token.service';

let isRefreshing = false;
const refreshTokenSubject = new BehaviorSubject<TokenData | null>(null);

export function authorizationInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const tokenService = inject(TokenService);
  const userStore = inject(UserStore);

  let authReq: HttpRequest<unknown> | null = null;
  if (req.url.startsWith('/api/') && !req.url.includes('token/revoke')) {
    const userToken: TokenData | null = tokenService.token;
    authReq = req.clone({
      ...req,
      url: environment.api + req.url,
      setHeaders: userToken
        ? {
            Authorization: userToken.tokenType + ' ' + userToken.accessToken,
          }
        : {},
      headers: req.headers.set('Accept-Language', userStore.language()),
    });
  }
  return next(authReq || req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        if (authReq) {
          if (!isRefreshing) {
            const refreshToken = tokenService.token.refreshToken;
            if (!refreshToken) return throwError(() => error);
            isRefreshing = true;
            refreshTokenSubject.next(null);
            return tokenService.acquireAccessToken(refreshToken).pipe(
              catchError((error) => {
                return throwError(() => error);
              }),
              switchMap((identityToken: RefreshTokenData) => {
                const mappedTokenModel: TokenData = {
                  accessToken: identityToken.access_token,
                  refreshToken: identityToken.refresh_token,
                  expiresIn: identityToken.expires_in,
                  tokenType: identityToken.token_type,
                };
                isRefreshing = false;
                tokenService.token = mappedTokenModel;
                refreshTokenSubject.next(mappedTokenModel);
                return next(
                  addTokenHeader(authReq, mappedTokenModel.accessToken),
                );
              }),
              catchError((error) => {
                isRefreshing = false;
                return throwError(() => error);
              }),
            );
          }
          if (refreshTokenSubject.value) {
            return refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              switchMap((token) =>
                next(addTokenHeader(authReq, token.accessToken)),
              ),
              catchError((error) => {
                return throwError(() => error);
              }),
            );
          } else {
            return throwError(() => error);
          }
        }
        return throwError(() => error);
      }
      return throwError(() => error);
    }),
  ) as Observable<HttpEvent<unknown>>;
}

function addTokenHeader(
  request: HttpRequest<any>,
  token: string,
): HttpRequest<any> {
  return request.clone({
    headers: request.headers.set('authorization', 'Bearer ' + token),
  });
}
