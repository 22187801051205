import { Injectable } from '@angular/core';
import localforage from 'localforage';
import { encryptObject, decryptObject } from '@functions';
import { environment } from '@environment';

const STORE_NAME = environment.sandbox ? 'blobs(dev)' : 'blobs';

@Injectable({
  providedIn: 'root',
})
export class BlobsStorageService {
  data = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
    name: 'weblauncher',
    version: 1.0,
    storeName: STORE_NAME,
    description: STORE_NAME,
  });

  async setBlob(key: string, value: Blob): Promise<any> {
    try {
      if (environment.sandbox) {
        return await this.data.setItem(key, value);
      } else {
        return await encryptObject(value).then((data) =>
          this.data.setItem(key, data),
        );
      }
    } catch (error) {
      return null;
    }
  }

  async getBlob(key: string): Promise<Blob | null> {
    try {
      if (environment.sandbox) {
        return await this.data.getItem(key);
      } else {
        return await this.data.getItem(key).then((data) => decryptObject(data));
      }
    } catch (error) {
      console.error('Error getting item from localForage', error);
      return null;
    }
  }

  async removeBlob(key: string): Promise<void> {
    try {
      return await this.data.removeItem(key);
    } catch (error) {
      console.error('Error removing item from localForage', error);
    }
  }

  async clear(): Promise<void> {
    try {
      return await localforage.clear();
    } catch (error) {
      console.error('Error clearing localForage', error);
    }
  }

  async getKeys(): Promise<string[]> {
    try {
      return await localforage.keys();
    } catch (error) {
      console.error('Error getting keys from localForage', error);
      return [];
    }
  }

  async length(): Promise<number> {
    try {
      return await localforage.length();
    } catch (error) {
      console.error('Error getting length of localForage', error);
      return 0;
    }
  }
}
