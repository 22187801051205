import { Injectable, signal } from '@angular/core';
import { environment } from '@environment';
import { decryptObject, encryptObject } from '@functions';
import { User } from '@models';
import { Buffer } from 'buffer';
import { argon2id, argon2Verify } from 'hash-wasm';
import localforage from 'localforage';

const STORE_NAME = environment.sandbox ? 'users(dev)' : 'users';

@Injectable({
  providedIn: 'root',
})
export class UsersStorageService {
  constructor() {
    this.getUsers();
  }

  usersDb = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
    name: 'weblauncher',
    version: 1.0,
    storeName: STORE_NAME,
    description: STORE_NAME,
  });

  users = signal<User[]>([]);

  async checkPassword(userEmail: string, password: string): Promise<boolean> {
    return this.getItem('local')
      .then((users) => {
        return users.find((localUser: User) => localUser.email === userEmail);
      })
      .then(async (data) =>
        argon2Verify({ password, hash: data.encryptedPassword }),
      )
      .then((isVerified) => {
        if (!isVerified) {
          throw new Error('Incorrect password');
        }
        return true;
      });
  }

  async savePassword(userEmail: string, password: string) {
    const salt = new Uint8Array(16);
    crypto.getRandomValues(salt);
    const encryptedPassword = await argon2id({
      password,
      salt,
      parallelism: 1,
      iterations: 512,
      memorySize: 1024,
      hashLength: 32,
      outputType: 'encoded',
    });

    this.getItem('local').then((users: User[]) => {
      this.setItem(
        'local',
        (users ?? []).map((localUser) =>
          localUser.email === userEmail
            ? { ...localUser, encryptedPassword }
            : localUser,
        ),
      );
    });
  }

  async saveUser(user: User): Promise<void> {
    const arrayBuffer = await user.image?.arrayBuffer();
    let imageBase64 = '';
    if (arrayBuffer) {
      imageBase64 = Buffer.from(arrayBuffer).toString('base64');
    }
    user = { ...user, imageBase64 };

    this.getItem('local').then((users: User[]) => {
      const existingUser = {
        ...(users ?? []).find(
          (existingUser) => existingUser.email === user.email,
        ),
      };
      const saveUser = {
        ...existingUser,
        ...user,
        guid: existingUser.guid ?? crypto.randomUUID(),
      } as any;
      delete saveUser.image;
      this.setItem('local', [
        ...(users ?? []).filter(
          (existingUser) => existingUser.email !== user.email,
        ),
        saveUser,
      ]);
    });
  }

  async removeUser(email: string): Promise<void> {
    this.getItem('local').then((users: User[]) => {
      this.setItem(
        'local',
        (users ?? []).filter((user) => user.email !== email),
      );
    });
  }

  async getUser(email: string): Promise<User> {
    return this.getItem('local')
      .then((users) => users.find((user: User) => user.email === email))
      .then((user) => this.convertUser(user) as User);
  }

  async getUsers(): Promise<any> {
    this.getItem('local')
      .then((users) =>
        Promise.all(
          users ?? [].map((user: any) => this.convertUser(user) as User),
        ),
      )
      .then((users) => {
        this.users.set(users);
      });
  }

  convertUser(user: any) {
    if (!user?.imageBase64) {
      return user;
    }
    const decodedBuffer = Buffer.from(user.imageBase64, 'base64');
    delete user.encryptedPassword;
    delete user.imageBase64;
    return {
      ...user,
      image: new Blob(
        [
          decodedBuffer.buffer.slice(
            decodedBuffer.byteOffset,
            decodedBuffer.byteOffset + decodedBuffer.byteLength,
          ),
        ],
        { type: 'image/png' },
      ),
    };
  }

  private async setItem(key: string, value: any): Promise<any> {
    try {
      if (environment.sandbox) {
        return await this.usersDb.setItem(key, value);
      } else {
        return await encryptObject(value).then((data) =>
          this.usersDb.setItem(key, data),
        );
      }
    } catch (error) {
      console.error('Error setting item in localForage', error);
      return null;
    }
  }

  private async getItem(key: string): Promise<any> {
    try {
      if (environment.sandbox) {
        return await this.usersDb.getItem(key).then((data) => {
          return data;
        });
      } else {
        return await decryptObject(await this.usersDb.getItem(key));
      }
    } catch (error) {
      console.error('Error getting item from localForage', error);
      return null;
    }
  }

  private async removeItem(key: string): Promise<void> {
    try {
      return await this.usersDb.removeItem(key);
    } catch (error) {
      console.error('Error removing item from localForage', error);
    }
  }

  private async clear(): Promise<void> {
    try {
      return await this.usersDb.clear();
    } catch (error) {
      console.error('Error clearing localForage', error);
    }
  }
}
