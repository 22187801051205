import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { SkillsWithChoosenLevelsAPI, UserAPI } from '@api';
import { AppSettings } from '@models';
import { getState } from '@ngrx/signals';
import { UserStore } from '@stores';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  #http = inject(HttpClient);
  #userStore = inject(UserStore);

  readonly userSkills = rxResource({
    loader: () =>
      this.#http
        .get<SkillsWithChoosenLevelsAPI>(
          `/api/users/${this.#userStore.id()}/skills`,
        )
        .pipe(map((skills) => skills.skillsWithChoosenLevels)),
  });

  readonly skills = rxResource({
    loader: () =>
      this.#http
        .get<{ skills: { name: string; id: number }[] }>(`/api/skills`)
        .pipe(map((skills) => skills.skills)),
  });

  getLevels(skillId: number) {
    return this.#http
      .get<{
        skillLevels: { name: string; id: number; order: number }[];
      }>(`/api/skills/${skillId}/levels`)
      .pipe(map((levels) => levels.skillLevels));
  }

  addSkill(levelId: number): Observable<any> {
    return this.#http.post(
      `/api/users/${this.#userStore.id()}/skillLevel/${levelId}`,
      null,
    );
  }

  removeSkill(levelId: number): Observable<any> {
    return this.#http.delete(
      `/api/users/${this.#userStore.id()}/skillLevel/${levelId}`,
    );
  }

  readonly usersettings = rxResource({
    loader: () =>
      this.#http.get<string>('/api/view/settings/4').pipe(
        map((userJsonString) => {
          const json = userJsonString.replace(/'/g, '"');
          return JSON.parse(json);
        }),
      ),
  });

  updateUserSettings(appSettings: AppSettings): Observable<any> {
    let data: any = JSON.stringify(appSettings);
    data = data.replace(/"/g, "'");
    return this.#http.post(`/api/view/settings/4`, `"${data}"`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
      }),
    });
  }

  updateUserDetails(userDetails: Partial<UserAPI>): Observable<any> {
    return this.#http.put('/api/users', {
      ...getState(this.#userStore),
      ...userDetails,
    });
  }

  updateUserPreferences(userDetails: Partial<UserAPI>): Observable<any> {
    return this.#http.put(
      `/api/users/${this.#userStore.id()}/preferences`,
      userDetails,
    );
  }

  replaceAvatar(avatar: Blob) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const fileToUpload = new FormData();
    fileToUpload.append('Image', avatar, 'avatar.png');
    return this.#http.put(
      `/api/users/${this.#userStore.id()}/images`,
      fileToUpload,
      { headers },
    );
  }

  changePassword(changePasswordDTO: {
    passwordOld: string;
    passwordNew: string;
    passwordNewConfirm: string;
  }): Observable<any> {
    return this.#http.put('/api/user/password', changePasswordDTO);
  }

  removeAccount() {
    const userName = this.#userStore.email();
    return this.#http.post('/api/user/removeAccountToken', {
      emailAddress: userName,
    });
  }
}
