import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReportUpload } from '@api';
import { base64ToBlob } from '@functions';
import { ScreenshotEntry, WebAppState, VideoEntry } from '@models';
import { SimEventService } from '@services';
import { AppService } from '@services';

let webAppState: WebAppState | null = 'inactive';
const sentImage: ScreenshotEntry[] = [];
const sentVideo: VideoEntry[] = [];
let sentReport: ReportUpload | null = null;

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private appService = inject(AppService);
  private router = inject(Router);
  private simEventService = inject(SimEventService);

  constructor() {
    (window as any)['setState'] = this.getState;
    (window as any)['sendImage'] = this.getImage;
    (window as any)['sendVideo'] = this.getVideo;
    (window as any)['sendReport'] = this.getReport;
    (window as any)['sendString'] = this.getString;

    setInterval(() => {
      if (sentImage.length > 0) {
        const image = sentImage.shift();
        if (image) {
          this.simEventService.addScreenshot(image);
        }
      }
      if (sentVideo) {
        const video = sentVideo.shift();
        if (video) {
          this.simEventService.addVideo(video);
        }
      }
      if (sentReport) {
        this.simEventService.addReport(sentReport);
        this.router.navigate(['/evaluation']);
        sentReport = null;
      }
      if (webAppState) {
        this.simEventService.updateWebAppState(webAppState);
        webAppState = null;
      }
    }, 500);
  }

  async startScene(sceneGuid: string, originalMultiSceneGuid?: string) {
    const sceneToStart = {
      SceneGuid: sceneGuid,
      OriginalMultiSceneGuid: originalMultiSceneGuid,
    };
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Start Scene: ', sceneToStart);

    const waitForUnityIframe = async (): Promise<HTMLIFrameElement> => {
      return new Promise((resolve, reject) => {
        const iframe = document.getElementById(
          'unityIframe',
        ) as HTMLIFrameElement;
        if (!iframe) {
          return reject('Iframe not found');
        }
        const checkIframeReady = () => {
          if (iframe.contentWindow) {
            resolve(iframe);
          } else {
            setTimeout(checkIframeReady, 300);
          }
        };
        checkIframeReady();
      });
    };

    const waitForMockIframe = async (): Promise<HTMLIFrameElement> => {
      const mockUnityInstance = {
        SendMessage: (object: string, method: string, parameters: string) => {
          this.appService.sentMessage.set({ object, method, parameters });
        },
      };
      return new Promise((resolve, reject) => {
        const iframe = document.getElementById(
          'mockIframe',
        ) as HTMLIFrameElement;
        if (iframe && iframe.contentWindow) {
          (iframe.contentWindow as any).unityInstance = mockUnityInstance;
        }
        resolve(iframe);
      });
    };

    try {
      const iframe = this.appService.mockMode()
        ? await waitForMockIframe()
        : await waitForUnityIframe();
      if (iframe) {
        (iframe as any).contentWindow.unityInstance.SendMessage(
          'WebLauncherConnector',
          'StartScene',
          JSON.stringify(sceneToStart),
        );
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error starting scene:', error);
    }
  }

  getState(state: string) {
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Get state:', state);
    webAppState = state.toLowerCase() as WebAppState;
  }

  getString(message: string) {
    // eslint-disable-next-line
    console.log('[WEBLAUNCHER] Get string:', message);
  }

  getImage(image: string) {
    const imageObject = JSON.parse(image);
    sentImage.push({
      ...imageObject,
      blob: base64ToBlob(imageObject.blob, imageObject.blobType),
    });
  }

  getVideo(video: string) {
    const videoObject = JSON.parse(video);
    sentVideo.push({
      ...videoObject,
      blob: base64ToBlob(videoObject.blob, videoObject.blobType),
    });
  }

  getReport(newReport: any) {
    const convertToCamelCase = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map((v) => convertToCamelCase(v));
      } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result: any, key: string) => {
          const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
          result[camelCaseKey] = convertToCamelCase(obj[key]);
          return result;
        }, {});
      }
      return obj;
    };
    sentReport = convertToCamelCase(JSON.parse(newReport));
  }
}
