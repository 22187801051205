import { inject, Injectable } from '@angular/core';
import { ReportUpload } from '@api';
import { SceneReport, UploadState, WebAppState } from '@models';
import { AppStore, ReportsStore, SceneStore, UserStore } from '@stores';
import { BlobsStorageService } from './blobs-storage.service';
import { ScreenshotEntry, VideoEntry } from '@models';

@Injectable({
  providedIn: 'root',
})
export class SimEventService {
  private appStore = inject(AppStore);
  private blobsStorageService = inject(BlobsStorageService);
  private reportsStore = inject(ReportsStore);
  private sceneStore = inject(SceneStore);
  private userStore = inject(UserStore);

  addScreenshot(screenshot: ScreenshotEntry) {
    this.sceneStore.addScreenshot(screenshot);
  }

  addVideo(video: VideoEntry) {
    this.sceneStore.addVideo(video);
  }

  async addReport(report: ReportUpload) {
    this.sceneStore.setReport(report);
    this.reportsStore.addReport({
      ...this.sceneStore.reportUpload(),
      identifier: crypto.randomUUID(),
      userId: this.userStore.id(),
      state: UploadState.pending,
    } as SceneReport);
    this.sceneStore.screenshots().forEach((screenshot) => {
      this.blobsStorageService.setBlob(screenshot.id, screenshot.blob);
    });
    this.sceneStore.videos().forEach((video) => {
      this.blobsStorageService.setBlob(video.id, video.blob);
    });
  }

  updateWebAppState(webAppState: WebAppState) {
    this.appStore.setWebAppState(webAppState);
  }
}
