import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ReportUpload } from '@api';
import { environment } from '@environment';
import { encryptReport } from '@functions';
import { SceneReport } from '@models';
import { DataStore, ReportsStore, SceneStore, UserStore } from '@stores';
import { Buffer } from 'buffer';
import {
  EMPTY,
  Observable,
  catchError,
  firstValueFrom,
  forkJoin,
  from,
  switchMap,
  tap,
} from 'rxjs';
import { BlobsStorageService } from './blobs-storage.service';

const CHUNK_SIZE = 1024 * 1024;

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private blobsStorageService = inject(BlobsStorageService);
  private dataStore = inject(DataStore);
  private http = inject(HttpClient);
  private reportsStore = inject(ReportsStore);
  private sceneStore = inject(SceneStore);
  private userStore = inject(UserStore);

  neverEndingContext = '7c7d351f-0967-46ef-8152-a0e2ed674bd3';

  private numberOfVideoChunks = 0;
  private chunkCount = 0;
  private sceneReport = {} as SceneReport;
  public fileUploadProgress = signal(0);
  isUploading = false;

  async uploadReport(identifier: string): Promise<boolean> {
    this.fileUploadProgress.set(0);
    const userId = this.createData(identifier);
    this.isUploading = true;
    try {
      const reportContext = await firstValueFrom(this.createContext(userId));
      const response = await firstValueFrom(this.submitReport(reportContext));

      this.sceneReport.data.movies.forEach(async (video) =>
        this.calculateNumberOfVideoChunks(
          (await this.blobsStorageService.getBlob(video.id)) as Blob,
        ),
      );

      if (this.sceneReport.data.screenshots.length > 0) {
        await firstValueFrom(
          forkJoin(
            this.sceneReport.data.screenshots.map((screenshot) =>
              from(
                this.blobsStorageService
                  .getBlob(screenshot.id)
                  .then((blob) =>
                    this.uploadScreenshot(
                      blob as Blob,
                      response[screenshot.id],
                    ),
                  ),
              ).pipe(switchMap((uploadObservable) => uploadObservable)),
            ),
          ).pipe(
            catchError((error) => {
              console.error('Error uploading screenshots', error);
              return EMPTY;
            }),
          ),
        );
      }

      if (this.sceneReport.data.movies.length > 0) {
        await firstValueFrom(
          forkJoin(
            this.sceneReport.data.movies.map((video) =>
              from(
                this.blobsStorageService
                  .getBlob(video.id)
                  .then((blob) =>
                    this.uploadBlobInChunks(blob as Blob, response[video.id]),
                  ),
              ).pipe(switchMap((uploadObservable) => uploadObservable)),
            ),
          ),
        );
      }
      return true;
    } catch (error) {
      console.error('UPLOAD ERROR', error);
      return false;
    } finally {
      this.isUploading = false;
    }
  }

  createData(identifier: string): number {
    const sceneReport = this.reportsStore
      .reports()
      .find((report) => report.identifier === identifier) as SceneReport;
    this.sceneReport = {
      ...sceneReport,
      data: {
        ...({} as ReportUpload),
        ...sceneReport.data,
        sceneNameLanguageKey: this.dataStore.selectedScene().nameLanguageKey,
        sceneSectionLanguageKey: this.dataStore.selectedScene().nameLanguageKey,
        actualSceneNameLanguageKey:
          this.dataStore.selectedScene().nameLanguageKey,
        id: crypto.randomUUID(),
        userSession: this.userStore.userSession()?.toString() ?? '',
      },
    };
    return sceneReport.userId;
  }

  createContext(userId: number): Observable<string> {
    return this.http.post<string>(`/api/user/${userId}/reports/createcontext`, {
      groupId: environment.adminGroupId,
      machineIdentifier: environment.simulatorIdentifier,
      sceneIdentifier: this.sceneReport.data.sceneIdentifier,
    });
  }

  submitReport(reportContext: string): Observable<{ [key: string]: string }> {
    return from(
      encryptReport(
        this.sceneReport.data as ReportUpload,
        'fxhToKHXW82SlxvdfrYsIXMak2RdmpLD',
      ),
    ).pipe(
      switchMap((encryptedUpload) =>
        this.http.post<{ [key: string]: string }>(
          `/api/user/reports/context/${reportContext}`,
          encryptedUpload.encrypted,
          {
            headers: {
              'Content-Type': 'application/json',
              apiKey: 'kpgwtlAF2WTawB3ZlcUUsmCIYZTL4E',
              iv: encryptedUpload.iv,
            },
          },
        ),
      ),
    );
  }

  calculateNumberOfVideoChunks(file: Blob) {
    this.numberOfVideoChunks =
      this.numberOfVideoChunks + Math.ceil(file.size / CHUNK_SIZE);
  }

  uploadScreenshot(blob: Blob, url: string) {
    return this.http
      .put(url, blob, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': blob.type,
        },
      })
      .pipe(
        tap(() => this.increaseChunkCount()),
        catchError(() => EMPTY),
      );
  }

  uploadBlobInChunks(blob: Blob, url: string) {
    const chunks: Blob[] = [];

    for (let offset = 0; offset < blob.size; offset += CHUNK_SIZE) {
      chunks.push(blob.slice(offset, offset + CHUNK_SIZE));
    }

    const blockIds: string[] = [];
    return forkJoin(
      chunks.map((chunk) => {
        const blockId = Buffer.from(crypto.randomUUID(), 'utf-8').toString(
          'base64',
        );
        blockIds.push(blockId);
        return this.http.put(`${url}&comp=block&blockid=${blockId}`, chunk, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': blob.type,
          },
        });
      }),
    ).pipe(
      tap(() => this.increaseChunkCount()),
      switchMap(() => {
        const requestBody = `<?xml version="1.0" encoding="utf-8"?>
          <BlockList>${blockIds.map((id) => `<Latest>${id}</Latest>`).join('')}</BlockList>`;
        return this.http.put(`${url}&comp=blocklist`, requestBody, {
          headers: {
            'Content-Type': 'text/plain; charset=UTF-8',
          },
        });
      }),
      catchError(() => EMPTY),
    );
  }

  increaseChunkCount() {
    this.chunkCount++;
    this.fileUploadProgress.set(
      100 *
        (this.chunkCount /
          (this.numberOfVideoChunks +
            this.sceneStore.screenshots().length +
            1)),
    );
  }
}
