import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService, SnackbarService } from '@services';
import { AppStore, UserStore } from '@stores';
import { CookieService } from 'ngx-cookie-service';
import { catchError, EMPTY, of, switchMap, tap } from 'rxjs';
import { TokenData } from './models/api/token.interfaces';
import { TokenService } from './token.service';
import { UserService } from '../services/user.service';
import { UsersStorageService } from './users-storage.service';

type Credentials = { username: string; password: string };

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private appStorageService = inject(AppStorageService);
  private appStore = inject(AppStore);
  private cookieService = inject(CookieService);
  private router = inject(Router);
  private snackbarService = inject(SnackbarService);
  private tokenService = inject(TokenService);
  private userService = inject(UserService);
  private usersStorageService = inject(UsersStorageService);
  private userStore = inject(UserStore);

  constructor() {
    this.appStorageService.getItem('user').then((user) => {
      this.userStore.updateUserData(user);
    });
  }

  loginUser(username: string, password: string, rememberMe = false) {
    this.appStorageService.getItem('userToken').then((token) => {
      if (token) {
        this.tokenService
          .revokeUserToken(username, password, token.accessToken)
          .subscribe(() => {
            this.getUserData({ username, password }, rememberMe);
          });
      } else {
        this.getUserData({ username, password }, rememberMe);
      }
    });
  }

  storageLogin() {
    this.appStorageService.getItem('userToken').then((token) => {
      if (token) {
        this.getUserData(token);
      }
    });
  }

  getUserData(login: Credentials | TokenData, rememberMe = false): void {
    const token = (login as TokenData).accessToken
      ? (login as TokenData)
      : null;
    (token
      ? of(token)
      : this.tokenService.getUserToken(
          (login as Credentials).username,
          (login as Credentials).password,
        )
    )
      .pipe(
        tap((token) => {
          this.tokenService.token = token;
          this.appStorageService.setItem('userToken', token);
        }),
        switchMap((userToken) =>
          this.userService.getUserAuthorizationData(
            token ? token.accessToken : userToken.accessToken,
          ),
        ),
        catchError(() => {
          this.snackbarService.showSnackbar({
            message: 'Could not login. Please try again.',
            severity: 'error',
            duration: 5000,
          });
          this.appStore.setLoginState('loggedOut');
          this.router.navigate(['login']);
          return EMPTY;
        }),
      )
      .subscribe(async (user) => {
        this.userStore.setRole(user.role);
        this.userService.loadUser();
        const userSession = await this.usersStorageService
          .getUser(this.userStore.email())
          .then((user) => user?.userSession);
        if (!userSession) {
          this.userStore.createUserSession();
        }
        if (rememberMe) {
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 1);
          this.cookieService.set(
            'username',
            this.userStore.email(),
            expirationDate,
          );
        }
      });
  }

  logoutUser() {
    this.userStore.clearUserSession();
    setTimeout(() => {
      this.userStore.clearUserData();
      this.appStorageService.removeItem('userToken');
      this.tokenService.token = {} as TokenData;
      this.appStorageService.removeItem('user');
      this.appStore.setLoginState('loggedOut');
      this.router.navigate(['login']);
    }, 300);
  }
}
