export const inputtext = (branding: 'virtamed' | 'manta') => ({
  colorScheme: {
    light: {
      root: {
        background: 'white',
        paddingY: '0.5rem',
        borderRadius: '0.5rem',
        width: '100%',
      },
    },
  },
});
