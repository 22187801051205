import { Buffer } from 'buffer';

export const base64ToBlob = (base64Data: string, contentType: string): Blob => {
  const base64 = base64Data.replace(
    /^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-+.]+;base64,/,
    '',
  );
  const byteArray = Buffer.from(base64, 'base64');
  return new Blob([byteArray], { type: contentType });
};

export const blobToBase64 = async (blob: Blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const buffer = Buffer.from(arrayBuffer);
  return buffer.toString('base64');
};
