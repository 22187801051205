export interface ReportUpload {
  actualSceneIdentifier: string;
  courseEntry: {
    courseEntryId: string;
    achievedStars: number;
    ensureCertificateIfAchieved: boolean;
  };
  moduleIdentifer: string;
  simulatorIdentifier: string;
  simulatorVersion: string;
  userInterfaceVersion: string;
  startDate: Date;
  endDate: Date;
  timeZoneString: string;
  hardwareComponents: {
    articleNumber: number;
    deviceType: number;
    identifier: string;
    productSerialNumber: string;
    serialNumber: number;
    sourceName: string;
    state: HardwareState;
    variants: string;
  }[];
  id: string;
  score: number;
  maxScore: number;
  selfAssesmentRating: number;
  simulationPreferences: {
    id: string;
    sortId: number;
    isVisible: boolean;
    nameLanguageKey: string;
    value: string;
    descriptionLanguageKey: string;
    preferenceType: number;
    name: string;
    minValue: number;
    maxValue: number;
    options: {
      descriptionLanguageKey: string;
      nameLanguageKey: string;
      value: string;
      sortId: number;
    }[];
  }[];
  questionnaire: {
    identifier: string;
    nameLanguageKey: string;
    descriptionLanguageKey: string;
    sortId: number;
    isVisible: boolean;
    score: number;
    maxScore: number;
    pages: {
      identifier: string;
      nameLanguageKey: string;
      descriptionLanguageKey: string;
      sortId: number;
      isVisible: boolean;
      pageType: number;
      rows: number;
      columns: number;
      sections: {
        identifier: string;
        nameLanguageKey: string;
        descriptionLanguageKey: string;
        sortId: number;
        isVisible: boolean;
        rows: number;
        columns: number;
        row: number;
        column: number;
        rowSpan: number;
        columnSpan: number;
        questions: {
          identifier: string;
          nameLanguageKey: string;
          descriptionLanguageKey: string;
          sortId: number;
          isVisible: boolean;
          score: number;
          maxScore: number;
          minScore: number;
          row: number;
          column: number;
          rowSpan: number;
          columnSpan: number;
          selectionMode: number;
          answerMode: number;
          orientation: number;
          answers: [
            {
              identifier: string;
              nameLanguageKey: string;
              descriptionLanguageKey: string;
              sortId: number;
              isVisible: boolean;
              isDefinedAsCorrect: boolean;
              isCorrectlyAnswered: boolean;
              scoreDefined: number;
              penaltyScoreDefined: number;
              scoreEvaluated: number;
              isSelected: boolean;
              userTaskIdentifier: string;
              states: number[];
              answerType: number;
              annotationGroupValueIdentifier: string;
            },
          ];
        }[];
      }[];
      annotationGroups: {
        identifier: string;
        nameLanguageKey: string;
        descriptionLanguageKey: string;
        sortId: number;
        isVisible: boolean;
        annotationKey: string;
        annotationValues: [
          {
            identifier: string;
            nameLanguageKey: string;
            descriptionLanguageKey: string;
            sortId: number;
            isVisible: boolean;
            value: string;
            unit: ReportUnit;
            propertyName: string;
            computationMethod: number;
            visibility: number;
            displayFormat: string;
            annotationScreenshotValues: {
              screenshotIdentifier: string;
              value: string;
              subGroup: string;
            }[];
            annotationGroupsReportItems: {
              reportItemIdentifier: string;
              computationMethod: number;
            }[];
          },
        ];
      }[];
      screenshotAssessment: {
        score: number;
        maxScore: number;
        screenshotIdentifier: string;
        selectedAssessmentImageBlobName: string;
      };
    }[];
  };
  reportSections: {
    id: string;
    sortId: number;
    isVisible: boolean;
    nameLanguageKey: string;
    sectionIdentifier: string;
    score: number;
    maxScore: number;
    descriptionLanguageKey: string;
    reportItems: ReportItem[];
  }[];
  sceneIdentifier: string;
  sceneNameLanguageKey: string;
  sceneSectionLanguageKey: string;
  actualSceneNameLanguageKey: string;
  simulationEvents: {
    id: string;
    sortId: number;
    isVisible: boolean;
    nameLanguageKey: string;
    ellapsedTimeOfOccurence: number;
    value: string;
    descriptionLanguageKey: string;
    severity: number;
    category: number;
  }[];
  status: Status;
  userTasks: UserTask[];
  notes: string;
  userSession: string;
  screenshots: Screenshot[];
  movies: Video[];
  reportAttachments: Attachment[];
  legacyQuestionnaire: {
    score: number;
    maxScore: number;
    correctQuestionsCount: number;
    totalQuestionsCount: number;
    answers: {
      answerIdentifier: string;
      isSet: boolean;
    }[];
  };
  incompleteAssesment: number;
  incompleteNotes: string;
}

export interface ReportItem {
  id: string;
  sortId: number;
  isVisible: boolean;
  nameLanguageKey: string;
  bestIs: BestIs;
  descriptionLanguageKey: string;
  displayMode: DisplayMode;
  displayStringFormat: string;
  forceScore: number;
  goalValueString: string;
  maxScore: number;
  minScore: number;
  maxValue: number;
  minValue: number;
  noEvalString: string;
  noScore: boolean;
  reportItemTags: {
    sortId: number;
    tagType: number;
  }[];
  reportMode: ReportMode;
  score: number;
  unit: ReportUnit;
  value: string;
  scoreMappingFunctionJson: string;
}
export interface UserTask {
  id: string;
  sortId: number;
  isVisible: boolean;
  nameLanguageKey: string;
  taskType: TaskType;
  isRequired: boolean;
  descriptionLanguageKey: string;
  state: TaskState;
  value: string;
  minValue: number;
  maxValue: number;
  valueToReach: number;
  isCountable: boolean;
  subTasks: string[];
  taskEvents: {
    nameLanguageKey: string;
    severity: Severity;
  }[];
}

export interface Screenshot {
  id: string;
  fileName: string;
  fileGeneratedName: string;
  groupLanguageKey: string;
  descriptionLanguageKey: string;
  nameLanguageKey: string;
  sortId: number;
}

export interface Video {
  id: string;
  fileName: string;
  fileGeneratedName: string;
  nameLanguageKey: string;
}

export interface Attachment {
  id: string;
  fileName: string;
  fileGeneratedName: string;
  fileType: number;
}

export enum ReportUnit {
  None,
  BoolValue,
  Integer,
  Percent,
  Meter,
  Centimeter,
  Millimeter,
  Kilometer,
  SquareMeter,
  Angle,
  Second,
  Minute,
  Hour,
  Day,
  Gram,
  Kilogram,
  Liter,
  CubicMeter,
  Newton,
  Joule,
  GramsPerMinute,
  Milliliter,
  MillilitersPerSecond,
  MillimeterPerSecond,
  CentimeterPerSecond,
  MeterPerSecond,
  Micrometer,
}

export enum Status {
  Unknown,
  Completed,
  Incomplete,
  Failed,
}

export enum BestIs {
  MaxValue,
  MinValue,
  MinMax,
  ZeroMax,
  ZeroMin,
  None,
}
enum DisplayMode {
  Default,
  String,
  LocalizedString,
  FloatingPoint,
  RoundedFloatingPoint,
  Integer,
  Bar,
  ColorBar,
  Time,
  DateTime,
  WeeksAndDays,
}

export enum ReportMode {
  Required,
  Optional,
}

export enum TaskType {
  UserTask,
  UserTaskGroup,
  UserTaskWithProgress,
  UserTaskWithValueToReach,
}

export enum TaskState {
  Undefined,
  Normal,
  Incomplete,
  InProgress,
  Completed,
  Failed,
}

enum HardwareState {
  Inactive,
  Disconnected,
  ErrorState,
  RequiresMaintenance,
  Active,
  Duplicated,
}

export enum Severity {
  Undefined,
  Warning,
  Error,
}
