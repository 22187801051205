export interface UserAPI {
  id: number;
  displayName: string;
  email: string;
  language: string;
  groupRoles: [
    {
      id: number;
      name: string;
      groupId: number;
    },
  ];
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  joinDate: string;
  imageUrl: string;
  handedness: Handedness;
  isNameAnonymized: boolean;
  hasDefaultPassword: boolean;
  isEmailConfirmed: boolean;
  isSignedUpToNewsletter: boolean;
  canBeContactedByVirtaMed: boolean;
  lastLoginDate: string;
  isActive: boolean;
  requestDeletionDate: string;
  isMedTech: boolean;
  company: Company;
}

export enum Handedness {
  NotSpecified,
  LeftHanded,
  RightHanded,
  Ambidextrous,
}

enum Company {
  VirtaMed,
  Ethicon,
}
