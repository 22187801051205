import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { GroupPermission, Role, UserAPI } from '@api';
import { environment } from '@environment';
import { User } from '@models';
import { AppStorageService } from '@services';
import { UserStore } from '@stores';
import { EMPTY, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { InitializationService } from '../authentication/initialization.service';
import { getRole } from '../functions';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private initializationService = inject(InitializationService);
  private http = inject(HttpClient);
  private appStorageService = inject(AppStorageService);
  private userStore = inject(UserStore);

  userInitialized = signal(false);

  getUserAuthorizationData(token: string): Observable<any> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('token', token);
    return this.http.post<any>('/api/users/identity/token/introspect', {});
  }

  loadUser(): void {
    this.http
      .get<UserAPI>('/api/users')
      .pipe(
        map((user) => {
          if (user) {
            fetch(user.imageUrl)
              .then((response) => response.blob())
              .then((blob) => {
                const userImageBlob = new Blob([blob], { type: 'image/png' });
                this.userStore.setImageBlob(userImageBlob);
              })
              .catch((error) =>
                console.error('Error fetching user image:', error),
              );
            return {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              language: user.language,
              dateOfBirth: user.dateOfBirth,
              handedness: user.handedness,
              isNameAnonymized: user.isNameAnonymized,
              isSignedUpToNewsletter: user.isSignedUpToNewsletter,
              canBeContactedByVirtaMed: user.canBeContactedByVirtaMed,
            };
          } else {
            return null;
          }
        }),
        catchError(() => {
          const user = this.appStorageService.getItem(
            'user',
          ) as unknown as User;
          if (user) {
            return of(user);
          }
          return EMPTY;
        }),
        tap((user) => {
          if (user) {
            this.userStore.updateUserData(user);
            this.initializationService.onlineInitalization();
            this.userInitialized.set(true);
          }
        }),
        switchMap((user) =>
          this.getUserRole(user?.id ?? 0, environment.adminGroupId),
        ),
        tap((role) => this.userStore.setRole(role as Role)),
      )
      .subscribe();
  }

  getUserRole(userId: number, groupId: number): Observable<string> {
    return this.http.get<any>(`/api/users/${userId}/permissions`).pipe(
      map(
        (permissions) =>
          permissions.groupPermissions.find(
            (permission: GroupPermission) => permission.groupId === groupId,
          )?.permissions,
      ),
      map((permissions) => (permissions ? getRole(permissions) : 'User')),
    );
  }
}
