import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '@models';
import { ReportsStore, UserStore } from '@stores';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private router = inject(Router);
  private reportsStore = inject(ReportsStore);
  private userStore = inject(UserStore);

  navigation = signal<Page>(Page.Scenes);

  navigationList = computed((): Page[] => {
    const startPage = this.userStore.appSettings.startPage();
    return [
      ...(this.adminNavigationList().some((item) => item === startPage)
        ? []
        : [startPage]),
      ...[
        'dashboard',
        'scenes',
        ...(this.reportsStore.currentReportId() ? ['evaluation'] : []),
      ].filter((item) => startPage !== item),
    ] as Page[];
  });

  adminNavigationList = computed((): Page[] => [Page.Uploads]);

  navigate(target?: string) {
    if (target && this.isNavigationItem(target)) {
      this.navigation.set(target);
    }
    this.router.navigate([this.navigation()]);
  }

  private isNavigationItem(target: string): target is Page {
    return [
      'scenes',
      'dashboard',
      'courses',
      'runner',
      'evaluation',
      'uploads',
    ].includes(target);
  }
}
