import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { decryptObject, encryptObject } from '@functions';
import localforage from 'localforage';

const STORE_NAME = environment.sandbox ? 'app(dev)' : 'app';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  data = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.WEBSQL,
      localforage.LOCALSTORAGE,
    ],
    name: 'weblauncher',
    version: 1.0,
    storeName: STORE_NAME,
    description: STORE_NAME,
  });

  async setItem(key: string, value: any): Promise<any> {
    try {
      if (environment.sandbox) {
        return await this.data.setItem(key, value);
      } else {
        return await encryptObject(value).then((data) =>
          this.data.setItem(key, data),
        );
      }
    } catch (error) {
      return null;
    }
  }

  async getItem(key: string): Promise<any> {
    if (environment.sandbox) {
      return await this.data.getItem(key);
    } else {
      return await this.data.getItem(key).then((data) => decryptObject(data));
    }
  }

  async removeItem(key: string): Promise<void> {
    try {
      return await this.data.removeItem(key);
    } catch (error) {
      console.error('Error removing item from localForage', error);
    }
  }

  async clear(): Promise<void> {
    try {
      return await localforage.clear();
    } catch (error) {
      console.error('Error clearing localForage', error);
    }
  }

  async getKeys(): Promise<string[]> {
    try {
      return await localforage.keys();
    } catch (error) {
      console.error('Error getting keys from localForage', error);
      return [];
    }
  }

  async length(): Promise<number> {
    try {
      return await localforage.length();
    } catch (error) {
      console.error('Error getting length of localForage', error);
      return 0;
    }
  }
}
