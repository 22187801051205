import { inject, Injectable } from '@angular/core';
import {
  SceneReportItem,
  SceneReportSection,
  SceneSimulationPreference,
  UserTask,
} from '@api';
import { SceneAndMultiScene } from '@models';
import { LocalizationService } from '@services';
import { XMLParser } from 'fast-xml-parser';

@Injectable({
  providedIn: 'root',
})
export class XmlParserService {
  localizationService = inject(LocalizationService);

  xmlSceneParser(xmlData: string) {
    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: '',
      trimValues: true,
      transformTagName: (tagName: string) => {
        return tagName
          .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
          .replace(/^./, (char) => char.toLowerCase());
      },
      transformAttributeName: (tagName: string) => {
        return tagName
          .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
          .replace(/^./, (char) => char.toLowerCase());
      },
    };

    const parser = new XMLParser(options);
    const jsonObj = parser.parse(xmlData);

    const sections = this.arrayConvert(jsonObj.sceneData.sections.section).map(
      (section: any) => ({
        identifier: section.guid,
        nameLanguageKey: section.nameLanguageKey,
        name: this.localizationService.getTranslation(section.nameLanguageKey),
        scenes: Object.entries(section.scenes)
          .flatMap((entries: any[]) =>
            entries[0] === 'scene'
              ? this.arrayConvert(entries[1])
              : this.arrayConvert(entries[1]).map((scene: any) => ({
                  ...scene,
                  multiScene: true,
                  scenesIdentifiers: this.arrayConvert(
                    scene.scenesIdentifiers.referencedScene,
                  ),
                })),
          )
          .map((scene: any) =>
            this.sceneMapper(scene, section.guid, section.nameLanguageKey),
          ),
      }),
    );
    return sections;
  }

  sceneMapper(
    xmlScene: any,
    sceneSectionIdentifier: string,
    sceneSectionNameLanguageKey: string,
  ): SceneAndMultiScene {
    const reportSections: SceneReportSection[] = xmlScene.reportConfig
      .reportSections
      ? this.arrayConvert(
          xmlScene.reportConfig.reportSections.reportSection,
        ).map((reportSection) => {
          const reportItems: SceneReportItem[] = this.arrayConvert(
            reportSection.reportItems.reportItem,
          ).map((xmlReportItem: any) => {
            return {
              identifier: xmlReportItem.guid,
              name: this.localizationService.getTranslation(
                xmlReportItem.nameLanguageKey,
              ),
              nameLanguageKey: xmlReportItem.nameLanguageKey,
              description: this.localizationService.getTranslation(
                xmlReportItem.descricptionLanguageKey,
              ),
              descriptionLanguageKey: xmlReportItem.descriptionLanguageKey,
              maxValue: xmlReportItem.maxValue,
              minValue: xmlReportItem.minValue,
              maxScore: xmlReportItem.maxScore,
              noScore: false,
              goalValueString: '',
              unit: xmlReportItem.unit,
              translatedUnit: '',
              bestIs: xmlReportItem.bestIs,
              displayMode: xmlReportItem.displayMode,
              scoreMappingFunctionEntries: [],
              isVisible: xmlReportItem.isVisible,
            };
          });
          return { ...reportSection, reportItems };
        })
      : [];

    const userTasks: UserTask[] = this.arrayConvert(
      xmlScene.userTasks.userTask,
    );

    const simulationPreferences: SceneSimulationPreference[] =
      this.arrayConvert(xmlScene.reportConfig.simulationPreferences);

    const scene: SceneAndMultiScene = {
      identifier: xmlScene.guid,
      name: this.localizationService.getTranslation(xmlScene.nameLanguageKey),
      nameLanguageKey: xmlScene.nameLanguageKey,
      description: '',
      groupName: '',
      sceneSectionName: xmlScene.sceneSectionName,
      sceneSectionNameLanguageKey,
      sceneSectionIdentifier,
      imageBlobUrl: '',
      reportSections,
      userTasks,
      simulationPreferences,
      descriptionItems: xmlScene.sceneDescriptionItems,
      movies: xmlScene.movies,
      recordingPath: '',
      multiScene: xmlScene.multiScene ?? false,
      scenesIdentifiers: xmlScene.scenesIdentifiers ?? [],
    };

    return scene;
  }

  arrayConvert(array: any) {
    return Array.isArray(array) ? array : [array];
  }

  xmlSimulatorParser(xmlData: string) {
    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: '',
      trimValues: true,
      transformTagName: (tagName: string) => {
        return tagName
          .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
          .replace(/^./, (char) => char.toLowerCase());
      },
      transformAttributeName: (tagName: string) => {
        return tagName
          .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
          .replace(/^./, (char) => char.toLowerCase());
      },
    };

    const parser = new XMLParser(options);
    const jsonObj = parser.parse(xmlData);

    return jsonObj.simVersion;
  }
}
