import { NgClass } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Debug } from '@components';
import { TranslateService } from '@ngx-translate/core';
import { usePreset } from '@primeng/themes';
import { AppService } from '@services';
import { AppStore } from '@stores';
import { preset } from 'styles/primeng/preset';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AppStorageService } from './core/services/app-storage.service';
import { ReportsStore } from './core/stores/reports.store';

@Component({
  imports: [Debug, NgClass, RouterModule],
  selector: 'wl-root',
  templateUrl: './app.html',
})
export class App {
  title = 'WebLauncher';
  protected appStore = inject(AppStore);
  protected appService = inject(AppService);
  private authenticationService = inject(AuthenticationService);
  private translate = inject(TranslateService);
  private appStorageService = inject(AppStorageService);
  private reportsStore = inject(ReportsStore);

  constructor() {
    effect(() => {
      usePreset(preset(this.appStore.branding()));
    });

    this.authenticationService.storageLogin();
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.appStorageService.getItem('reports').then((reports) => {
      if (reports) {
        this.reportsStore.setReports(reports);
      }
    });
  }
}
