import { NgClass } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Debug } from '@components';
import { TranslateService } from '@ngx-translate/core';
import { usePreset } from '@primeng/themes';
import { AppService } from '@services';
import { AppStore } from '@stores';
import { preset } from 'styles/primeng/preset';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AppStorageService } from './core/services/app-storage.service';
import { ReportsStore } from './core/stores/reports.store';

@Component({
  imports: [Debug, NgClass, RouterModule],
  selector: 'wl-root',
  templateUrl: './app.html',
})
export class App {
  title = 'WebLauncher';
  protected appStore = inject(AppStore);
  protected appService = inject(AppService);
  private authenticationService = inject(AuthenticationService);
  private translate = inject(TranslateService);
  private appStorageService = inject(AppStorageService);
  private reportsStore = inject(ReportsStore);

  constructor() {
    this.checkLock();
    effect(() => {
      usePreset(preset(this.appStore.branding()));
    });

    this.authenticationService.storageLogin();
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.appStorageService.getItem('reports').then((reports) => {
      if (reports) {
        this.reportsStore.setReports(reports);
      }
    });
  }

  async checkLock() {
    const storedCredentialId = localStorage.getItem('registeredCredentialId');
    if (!storedCredentialId) {
      /* eslint-disable */
      console.error('No key registered on this device.');
      return false;
    }

    const credentialId = Uint8Array.from(atob(storedCredentialId), (c) =>
      c.charCodeAt(0),
    );
    const publicKey = {
      challenge: Uint8Array.from('random-check-challenge', (c) =>
        c.charCodeAt(0),
      ), // A dummy challenge
      allowCredentials: [
        {
          id: credentialId,
          type: 'public-key',
        },
      ],
      userVerification: 'discouraged',
    } as any;

    try {
      // Try to retrieve the credential
      const assertion = await navigator.credentials.get({ publicKey });
      /* eslint-disable */
      console.log('Credential found:', assertion);
      alert('The key is registered on this device!');
      return true;
    } catch (err) {
      console.error('Credential not found or error:', err);
      alert('The key is NOT registered on this device.');
      return false;
    }
  }
}
