import { NgClass } from '@angular/common';
import {
  ApplicationRef,
  Component,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  inject,
  Injectable,
  input,
} from '@angular/core';

type Snack = {
  message: string;
  severity?: Severity;
  duration?: number;
};

type Severity = 'info' | 'success' | 'error';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private appRef = inject(ApplicationRef);
  private injector = inject(EnvironmentInjector);

  snackbars: Snack[] = [];

  showSnackbar(snack: Snack) {
    this.snackbars.push(snack);
    if (this.snackbars.length === 1) {
      this.createSnackbar();
    }
  }

  private createSnackbar() {
    const snack = this.snackbars[0];
    const componentRef: ComponentRef<Snackbar> = createComponent(Snackbar, {
      environmentInjector: this.injector,
    });

    this.appRef.attachView(componentRef.hostView);

    const rootNode = (componentRef.hostView as any).rootNodes[0];
    document.body.appendChild(rootNode);

    componentRef.setInput('message', snack?.message);
    componentRef.setInput('severity', snack?.severity);

    setTimeout(() => {
      componentRef.destroy();
      this.snackbars.shift();
      if (this.snackbars.length > 0) {
        setTimeout(() => {
          this.createSnackbar();
        }, 500);
      }
    }, snack?.duration ?? 3000);
  }
}

@Component({
  selector: 'wl-snackbar',
  imports: [NgClass],
  template: `<div
    class="pointer-events-none absolute top-0 z-[999999] flex h-screen w-screen flex-col items-center justify-end overflow-hidden py-12"
  >
    <div
      class="text-wl-white animate-pop pointer-events-auto flex justify-center rounded-md p-2 px-20 shadow-md backdrop-blur-md"
      [ngClass]="
        severity() === 'success'
          ? 'bg-wl-green/80'
          : severity() === 'error'
            ? 'bg-wl-red/80'
            : 'bg-wl-black/70'
      "
    >
      {{ message() }}
    </div>
  </div>`,
})
class Snackbar {
  message = input.required<string>();
  severity = input<Severity>('info');
}
