import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Page } from '@models';
import { NavigationService } from '@services';
import { UserStore } from '@stores';
import { AuthenticationService } from '../authentication.service';
import { AppStorageService } from '@services';
import { runInInjectionContext, EnvironmentInjector } from '@angular/core';

export const authorizationGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  inject(NavigationService).navigation.set(state.url.slice(1) as Page);
  const isAuthenticated = inject(UserStore).isAuthenticated();

  let user = null;
  await runInInjectionContext(inject(EnvironmentInjector), async () => {
    user = await inject(AppStorageService)
      .getItem('user')
      .then((user) => user?.email);
  });
  if (isAuthenticated || user) {
    return true;
  }
  inject(AuthenticationService).logoutUser();
  return false;
};
