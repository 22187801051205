<div
  class="relative flex h-screen w-screen flex-col overflow-hidden"
  [ngClass]="appStore.branding() === 'manta' ? 'manta-theme' : 'virtamed'"
>
  <router-outlet> </router-outlet>
  @defer (when appService.debugMode()) {
    @if (appService.debugMode()) {
      <wl-debug class="z-50" />
    }
  }
</div>
