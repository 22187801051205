import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  debugMode = signal(false);
  mockMode = signal(false);
  sentMessage = signal({ object: '', method: '', parameters: {} });
}
